import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUserInfo } from '../../api';
import { getToken } from '../../utils/auth';

const initialState = {
    permList: [],
    user: null,
};

export const getInfoByToken = createAsyncThunk('userInfo/userInfoObj', async () => {
    const token = getToken();
    if (!token) return Promise.reject();
    let result = await getUserInfo();
    return result;
})

export const userInfoSlice = createSlice({
    name: 'userInfo',
    initialState,
    reducers: {
        // add(state, { payload }) {
        //     //在当前页，点击关闭页面时，会触发useEffect的组件卸载函数，添加一条记录，此时state.Ignore保证了不添加记录
        //     if (state.historyRoute[payload.key] === state.Ignore) {
        //         state.historyRoute[payload.key] = {};
        //         return;
        //     }
        //     state.historyRoute[payload.key] = { ...payload.value };
        //     for (let i of state.historyList) {
        //         if (i.key === payload.key) {
        //             return;
        //         }
        //     }
        //     state.historyList = [...state.historyList, { key: payload.key, name: payload.value.name }];
        // },
    },
    extraReducers: {
        [getInfoByToken.fulfilled](state, { payload }) {
            if (payload.code === 200) {
                const set = new Set();
                for (let i of payload.data.menuList) {
                    set.add(i.id + '');
                }
                const halfIds = payload.data.halfIds.reduce((prev, cur) => { prev.push(...cur.split(',')); return prev }, []);
                for (let i of halfIds) {
                    set.add(i);
                }
                state.permList = [...set];
                state.user = payload.data.userinfo;
            }
        },
    }
})

// export const { change } = userInfoSlice.actions;

export default userInfoSlice.reducer;