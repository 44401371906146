import axios from 'axios';
import { getToken } from '../utils/auth'

let flag = false;

// 创建axios实例
const service = axios.create({
    // baseURL: process.env.BASE_API, // api的base_url
    timeout: 50000, // 请求超时时间
});

// request拦截器
service.interceptors.request.use(config => {
    const token = getToken();
    if (token) {
        config.headers.authorization = token;
    }
    return config;
}, error => {
    Promise.reject(error);
})

// respone拦截器
service.interceptors.response.use(
    res => {
        if (flag) {
            return new Promise(() => { });
        } else if (res.status === 200 || res.status === 304) {
            if (res.data.code === 401) {
                flag = true;
                if (window.confirm('token已过期，请重新登录！')) {
                    localStorage.removeItem('userToken');
                    window.history.replaceState(null, null, '/login');
                    window.location.reload();
                } else {
                    console.log('cancel')
                    return res.data;
                }
            }
            else {
                return res.data;
            }
        }
        else {
            flag = false;
            console.log('网络不太给力哦，检查一下您的网络再试吧！')
        }
    },
    error => {
        console.log('err', error.response); // for debug
        console.log('网络不太给力哦，检查一下您的网络再试吧！')
        return Promise.reject(error);
    },
);

export default service;


