import React, { useEffect, useState } from 'react'
import { Form, Input, Button, message, Image, Checkbox } from 'antd';
import { LockOutlined, UserOutlined, InsuranceOutlined } from '@ant-design/icons';
import css from './index.module.scss';
import { useNavigate } from 'react-router-dom';
import { Login as doLogin, getValidateCode } from '../../api';
import { useDispatch, useSelector } from 'react-redux';
import { getInfoByToken } from '../../store/features/userInfoSlice';

export default function Login() {
  const navigate = useNavigate();
  const [imgURL, setImgURL] = useState('');
  const [loading, setLoading] = useState(false);
  const [loginform] = Form.useForm();
  const dispatch = useDispatch();
  const perm = useSelector(state => state.userInfo.permList);

  useEffect(() => {
    const userInfo = localStorage.getItem('formInfo') ? JSON.parse(localStorage.getItem('formInfo')) : [];
    if (userInfo?.password) {
      loginform.setFieldsValue({
        ...userInfo
      })
    }
    // getCode();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  const onFinish = async (values) => {
    const data = {
      username: values.username,
      password: values.password,
      code: values.validatecode
    };
    setLoading(true);
    const result = await doLogin(data);
    if (result.code === 200) {
      message.success({ content: result.message ? result.message : '登录成功！', duration: 2 });
      // localStorage.setItem('menuList', JSON.stringify(result.menuList));
      localStorage.setItem('userToken', result.data.Authorization);
      // console.log(result);
      if (values.remember) {
        localStorage.setItem('formInfo', JSON.stringify({
          username: values.username,
          password: values.password
        }))
      } else {
        localStorage.removeItem('formInfo');
      }
      dispatch(getInfoByToken());
      navigate('/home/display/index');
    } else {
      message.error({ content: result.message ? result.message : '登录失败！', duration: 2 });
      // getCode();
    }
    setLoading(false);
  };
  const onFinishFailed = (errorInfo) => {

  };
  // const getCode = async () => {
  //   const result = await getValidateCode();
  //   setImgURL(window.URL.createObjectURL(result));
  // }

  return (
    <div className={css.background}>
      <div className={css.topbox} >
        <div className={css.toptitle}>Hi,你好！</div>
        <div>欢迎使用新利轻松收！</div>
      </div>
      <div className={css.bg}></div>
      <div className={css.box}>
        <div style={{ fontSize: '25px', marginBottom: '40px', textAlign: 'center' }}>登录</div>
        <Form
          name="loginform"
          form={loginform}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="username"
            style={{ marginBottom: 35 }}
            rules={[
              {
                required: true,
                message: '请输入用户名!',
              },
            ]}
          >
            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="用户名" />
          </Form.Item>
          <Form.Item
            name="password"
            style={{ marginBottom: 35 }}
            rules={[
              {
                required: true,
                message: '请输入密码!',
              },
            ]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="密码"
            />
          </Form.Item>
          <Form.Item style={{ position: 'relative', marginBottom: '25px' }}>
            <Form.Item
              style={{ width: '70%', marginBottom: 0 }}
              name="validatecode"
            >
              <Input prefix={<InsuranceOutlined className="site-form-item-icon" />} placeholder="验证码" />
            </Form.Item>
            <Image src={imgURL} height={32} rootClassName={css.img} preview={false} onClick={() => { }} placeholder />
          </Form.Item>
          <Form.Item style={{ position: 'relative', marginBottom: '15px' }}>
            <Form.Item name="remember" valuePropName="checked" style={{ marginBottom: 0 }}>
              <Checkbox>记住密码</Checkbox>
            </Form.Item>

            <a href="/#" className="login-form-forgot" style={{ float: 'right', position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)' }}>
              忘记密码？
            </a>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" className={css.loginbtn} loading={loading}>
              登录
            </Button>
          </Form.Item>
        </Form>
        {/* <div style={{ textAlign: 'center', fontSize: '12px' }}>
          <div>请使用IE8及以上版本浏览器</div>
          <div>360浏览器请切换到极速模式(<a href="/#" style={{ textDecoration: 'none', color: '#1296db', cursor: 'pointer' }} >点击查看切换方法</a>)</div>
          <div>推荐使用Google Chrome浏览器(<a href="/#" style={{ textDecoration: 'none', color: '#1296db', cursor: 'pointer' }} >点击下载</a>)</div>
        </div> */}
      </div>
    </div>
  );

}
