import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    historyRoute: {},
    historyList: [],
    Ignore: 'Ignore____',
};

export const historySlice = createSlice({
    name: 'history',
    initialState,
    reducers: {
        add(state, { payload }) {
            //在当前页，点击关闭页面时，会触发useEffect的组件卸载函数，添加一条记录，此时state.Ignore保证了不添加记录
            if (state.historyRoute[payload.key] === state.Ignore) {
                state.historyRoute[payload.key] = {};
                return;
            }
            state.historyRoute[payload.key] = { ...payload.value };
            for (let i of state.historyList) {
                if (i.key === payload.key) {
                    return;
                }
            }
            state.historyList = [...state.historyList, { key: payload.key, name: payload.value.name }];
        },
        deleteListItemWithIgnore(state, { payload }) {
            state.historyRoute[payload.key] = state.Ignore;
            state.historyList.splice(payload.index, 1);
        },
        deleteListItem(state, { payload }) {
            state.historyRoute[payload.key] = {};
            state.historyList.splice(payload.index, 1);
        },
    }
})

export const { add, deleteListItem, deleteListItemWithIgnore } = historySlice.actions;

export default historySlice.reducer;