import { configureStore } from '@reduxjs/toolkit';

import historySlice from './features/historySlice';
import userInfoSlice from './features/userInfoSlice';

export const store = configureStore({
    reducer: {
        history: historySlice,
        userInfo: userInfoSlice
    }
})

