/* 
    可配置多级路由，添加权限修改Layout中的createMenu方法，
    createMenu方法会将router数组第一个对象渲染到侧边栏，其它路由隐藏，如登录、打印、404页面
*/
import { lazy } from "react";
import Login from "../Pages/Login";
import Display from "../Pages/Display";
import AppLayout from "../Pages/Layout";
import NotFound from '../Pages/NotFound';
import { Navigate } from "react-router-dom";
import { BankOutlined, EditOutlined, MenuOutlined, SettingOutlined, UserOutlined, UsergroupAddOutlined, OrderedListOutlined, UserAddOutlined, HomeOutlined, RadarChartOutlined } from "@ant-design/icons";

const EnterPrise = lazy(() => {
  return import("../Pages/Enterprise");
});
const User = lazy(() => {
  return import("../Pages/User");
});
const Role = lazy(() => {
  return import("../Pages/Role");
});
const Menu = lazy(() => {
  return import("../Pages/Menu");
});
const Merchant = lazy(() => {
  return import("../Pages/Merchant");
});
const People = lazy(() => {
  return import("../Pages/People");
});
const Groups = lazy(() => {
  return import("../Pages/Group");
});
const Bill = lazy(() => {
  return import("../Pages/Bill");
});
const Refund = lazy(() => {
  return import("../Pages/Refund");
});

const router = [
  // These are the same as the props you provide to <Route>
  {
    id: "5",
    path: '/home',
    element: <AppLayout />,
    children: [
      {
        id: "display1",
        auth: true,
        path: "/home/display",
        title: "首页",
        icon: <HomeOutlined />,
        children: [
          {
            id: "display2",
            auth: true,
            path: "/home/display/index",
            title: "首页",
            icon: <RadarChartOutlined />,
            element: <Display />,
          },
        ],
      },
      {
        id: "8",
        path: '/home/system',
        title: '系统管理',
        icon: <SettingOutlined />,
        children: [
          {
            id: "12",
            path: "/home/system/user",
            title: "用户管理",
            icon: <UserOutlined />,
            element: <User />,
          },
          {
            id: "13",
            path: "/home/system/role",
            title: "角色管理",
            icon: <UsergroupAddOutlined />,
            element: <Role />,
          },
          {
            id: "14",
            path: "/home/system/menu",
            title: "菜单管理",
            icon: <MenuOutlined />,
            element: <Menu />,
          },
          {
            id: "17",
            path: "/home/system/merchant",
            title: "商户管理",
            icon: <BankOutlined />,
            element: <Merchant />,
          },
        ],
      },
      {
        id: "11",
        path: "/home/deco",
        title: "项目管理",
        icon: <OrderedListOutlined />,
        children: [
          {
            id: "15",
            path: "/home/deco/manage",
            title: "收款项目管理",
            icon: <EditOutlined />,
            element: <EnterPrise />,
          },
        ],
      },
      {
        id: "18",
        path: "/home/users",
        title: "人群管理",
        icon: <UserOutlined />,
        children: [
          {
            id: "19",
            path: "/home/users/single",
            title: "单个用户管理",
            icon: <UserAddOutlined />,
            element: <People />,
          },
          {
            id: "20",
            path: "/home/users/group",
            title: "人群包管理",
            icon: <UsergroupAddOutlined />,
            element: <Groups />,
          },
        ],
      },
      {
        id: "21",
        path: "/home/bill",
        title: "账单管理",
        icon: <OrderedListOutlined />,
        children: [
          {
            id: "22",
            path: "/home/bill/list",
            title: "账单列表",
            icon: <EditOutlined />,
            element: <Bill />,
          },
          {
            id: "23",
            path: "/home/bill/refund",
            title: "退款列表",
            icon: <EditOutlined />,
            element: <Refund />,
          }
        ],
      },
    ],
  },
  { path: "/login", element: <Login /> },
  { path: '/404', element: <NotFound /> },
  { path: "*", element: <Navigate to="/404" /> },
];
export default router;

const dfs = (node, map = new Map()) => {
  if (!node.id) return;
  map.set(node.id, node);
  if (node.children) {
    node.children.forEach(e => dfs(e, map));
  }
  return map;
}

export const getList = () => {
  return dfs(router[0]);
}
