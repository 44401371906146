import './App.less';

import { memo, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import { useSelector, useDispatch } from 'react-redux';
import { getInfoByToken } from './store/features/userInfoSlice';
import router from './router/index';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
// import { Navigate } from 'react-router-dom';

const createMenuTree = (data, perms) => {
  if (!data.children) {
    return <Route key={data.path} path={data.path} element={data.element} icon={data.icon}></Route>;
  }
  const list = [];
  for (let i = 0; i < data.children.length; i++) {
    if (data.children[i].auth === true) {
      list.push(createMenuTree(data.children[i], perms));
      continue;
    }
    if (!perms.includes(data.children[i].id)) {
      continue;
    }
    list.push(createMenuTree(data.children[i], perms));
  }
  return <Route key={data.path} path={data.path} element={data.element} icon={data.icon}>{list}</Route>;
}

function App() {
  dayjs.locale('zh-cn');
  const perms = useSelector(state => state.userInfo.permList);
  const dispatch = useDispatch();

  const theme =
  {
    token: {
      colorPrimary: '#006efd',
    },
  }

  useEffect(() => {
    dispatch(getInfoByToken());
  }, [dispatch]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <ConfigProvider locale={zhCN} theme={theme}
      >
        <Routes>{perms && perms.length ? createMenuTree(router[0], perms) : router.slice(0, 1).map(data => <Route key={data.path} path={data.path} element={data.element}></Route>)}
          {router.slice(1, -1).map(data => <Route key={data.path} path={data.path} element={data.element}></Route>)}
          {perms && perms.length ? router.slice(-1).map(data => <Route key={data.path} path={data.path} element={data.element}></Route>) : null}
        </Routes>
      </ConfigProvider>
    </div>
  );
}

export default memo(App);
